.contact-dialog .dialog-body > p {
  line-height: 1.5;
  letter-spacing: 0.7px;
  margin: 0 0 1rem;
}
.contact-dialog ._comp_tech_phone-number {
  margin: 1rem 0;
  display: inline-block;
}
.contact-dialog .dialog-footer {
  padding: 1rem;
  background-color: var(--color-brand-900);
  background-image: linear-gradient(var(--color-brand-900), var(--color-blue-800));
  color: #fff;
}
.contact-dialog .dialog-footer p {
  margin: 0;
}